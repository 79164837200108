import {CareEntrySearchResult, RelatedTopic} from "../../../utils/cms/types";
import CareEntryListRow from "./Control";

export type ExtendedCareEntrySearchResult = Partial<CareEntrySearchResult & RelatedTopic> & {
  slug: string;
  name: string;
  icon?: string;
  turnaround?: string;
  price?: string;
};

export enum CareEntryRowVariant {
  SM = "sm",
  MD = "md",
  LG = "lg",
}

export default CareEntryListRow;
