import {useRouter as useAppRouter} from "next/navigation";
import {useRouter as usePagesRouter} from "next/router";

type UsePushOptions = {
  locale?: string;
};

function useCreateRouterPush(options: UsePushOptions): () => RouterPush {
  try {
    const router = usePagesRouter();
    const {locale} = options;
    return () => r => router.push(r, r, {locale});
  } catch {
    return createUseAppRouterPush(options);
  }
}

function createUseAppRouterPush(options: UsePushOptions): () => RouterPush {
  const {locale} = options;
  return () => {
    const router = useAppRouter();

    return r => {
      if (!locale) return router.push(r);

      // Add lang as a query parameter to the path
      const url = new URL(r);
      url.searchParams.set("lang", locale);
      const route = url.toString();

      router.push(route);
    };
  };
}

/**
 * Function that navigates to the given route.
 */
type RouterPush = (route: string) => void;

/**
 * Navigate to a given route from a client component,
 * regardless of whether that component is in the app directory or pages directory.
 *
 * @example
 * const push = usePush();
 * push("/locations/berkeley");
 */
export default function usePush(options: UsePushOptions = {}): RouterPush {
  const createRouterPush = useCreateRouterPush(options);
  return createRouterPush();
}
